import React from "react";
import Mobile from "./components/mobile/Mobile";
import { Container, Typography } from "@mui/material";
import { Provider } from "react-redux";
import store from "./components/redux";
//@ts-ignore
const tg = window.Telegram?.WebApp;

function App() {
  return (
    <Provider store={store}>
      <Container maxWidth="sm">
        {tg ? <Mobile /> : <Typography variant="h5">Зайдите через телеграм</Typography>}
      </Container>
    </Provider>
  );
}

export default App;
